import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
    .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
    debug: true,
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        nl : {
            translation : {
                siteData : {
                    header : "Spy3k" , 
                    subheader : "Iedereen verdient de waarheid!"
                } ,
                menu : {
                    home : "Home" , 
                    products : "Producten" , 
                    contact : "Contact" ,
                    companies : "Bedrijven" ,
                    family : "Families" ,
                    pi : "Privé Detectives"
                } ,
                site : {
                    intro : "Spy3k levert discrete video en luister oplossingen voor iedereen die een oogje in het zeil moet houden." ,
                    companies : {
                        header : "Je winst beschermen, je zaak beschermen door gebruikt te maken van onze kostefficiënte surveillance oplossingen." ,
                        intro : [
                            "Het is zeker geen fijne gedachte als je jouw werknemers van diefstal verdenkt. De realiteit liegt echter niet: Regelmatig lezen we in het nieuws over geleden schade door interne fraude. Een Europese studie toont aan dat een bedrijf per jaar enkele duizenden tot tienduizenden euro’s of meer aan interne diefstal verliest en dat dit vaak door het eigen personeel gebeurt. De huidige crisis ondermijnt de koopkracht van de consument en zo ligt diefstal op het werk jammer genoeg veel hoger. Deze finaciële crisis heeft ook een invloed op jouw omzet. En zeker tijdens tijden van verminderde koopkracht kan je diestal door oneerlijk personeel best missen. Het is uiteindelijk jouw bedrijfen jij hebt dan ook het recht om te weten wie steelt en wat er gestolen wordt. Dus als je onzeker bent en je de twijfel beu bent: Kan je jouw vermoedens inruilen voor duidelijke antwoorden door gebruik te maken van onze surveillance toepassingen." ,
                            "Als zijnde een serieuze bedrijfsleider kan je eenvoudig geen lekkende informatie, plichtsverzuim, fraude of andere malafide praktijken toestaan. Kleine bedrijven lopen dikwijls het grootste risico om onderuit te gaan en de boeken te moeten sluiten door malafide mensen onder het personeel. Nu je de onaangename en harde feiten kent, hoef je niet langer te twijfelen om controles uit te gaan voeren. Men zegt vaak waar rook is, is er ook vuur en deze stelling is dikwijls waar. Je neemt zo je eigen zaak en de werkgelegenheid voor oprecht personeel in bescherming."
                        ] ,
                        testimony : {
                            title : "Getuigenis door Joris - Zaakvoerder groothandel - computer hardware" ,
                            text : "Sinds een hele tijd werd er ongeveer 7.000€ per maand verijdelt uit onze eigen magazijnen. Dit begon met sporadische onschuldige bedragen maar de cijfers bleven maar stijgen en we leden grotere en grotere verliezen. Mocht ik deze praktijken geen halt hebben toegeroepen, had ik de boeken van mijn bedrijf kunnen neerleggen. Wij hebben 50 personeelsleden in dienst en konden dankzij de apparatuur van Spy3K de schuldigen vatten met harde bewijzen bij de hand. Ons personeel moet weten dat dit gedrag onaanvaardbaar is en dat ik als steekproef soms nog controles uitvoer. Het algehele contact met de personeelsleden is enorm verbetert daar oprechte en eerlijke werknemers niet langer onterecht verdacht kunnen worden."
                        }
                    } ,
                    family : {
                        header : "Wil ook jij weten wat achter je rug omgaat door gebruik te maken van onze praktische surveillance oplossingen?" ,
                        intro : [
                        " 7 op 7 en 24 op 24 op je kinderen, je wagen en je computer, huis, kindermeisje, … enz letten is een onmogelijke opdracht. En daar je je niet in 10 stukken kan verdelen, kan je gebruik maken van onze gebruikersvriendelijke surveillance apparaten die dat voor je oplossen. Stop dus met je intuitie in vraag te stellen want je kan de bewijzen hebben die je zoekt om je gezin en je huis met een gerust hart achter te laten."
                        ] ,
                        testimony : {
                            title : " Getuigenis door Kris - Financieel directeur" ,
                            text : " Elke dinsdag, als mijn vrouw en ik beide op het werk zijn, komt de poetsdame langs. Onze vaste dame was echter op vakantie en er werd ons dus een anderen poetsdame aangewezen. We stelden vast dat er geld en spullen verdwenen maar echt zeker waren we niet om haar hiervan te beschuldigen. Tot mijn Rolex uurwerk van 9500€ verdwenen leek te zijn. We informeerden de politie en markeerde het geld in de kassa. Het ontvreemden van gemarkeerde geld werd opgenomen met een Spy3K onopvallende mini camera en zo kon de politie de nodige stappen ondernemen. Onze vervreemde spullen, het uurwerk inclusief, werden bij de poetsdame thuis teruggevonden."
                    }
                }  ,
                pi : {
                    header : "Pro spionage apparatuur voor uiterst professionele resultaten" ,
                    intro : [
                        "Het internet, Ebay, je gadget shop, ... ze hebben allemaal ‘spy apparatuur’ voorradig. Maar zeker niet alle toestellen zijn het vermelden waard. Beter, 90% van deze aangeboden spionage apparatuur is mogelijk onbruikbaar. Het is dus lastig om de juiste en goed werkende apparatuur uit te kiezen waarop je kan vertrouwen zodat je geen figuur slaat bij je klanten." ,
                        "Je kan zomaar een spionage toestel gaan bestellen en er de teleurstelling, kosten en tijdsverlies gratis bij krijgen of je laat ons je begeleiden bij je keuze. Het gamma van Spy3K producten werkt met twee niveau's.<br />1: Zo hebben we producten met een uiterst sterke prijs - kwaliteitsverhouding die echt doen wat ze beloven en de gewone gebruiker de gewenste antwoorden kan geven.<br />2: En dan hebben we de beste producten die uitstekende resultaten opleveren, die bestemd zijn voor professioneel gebruik."
                    ] 
                } ,
                contact : {
                    title : "Contact" ,
                    openingsuren : {
                        title : "Openingsuren" , 
                        text : "Enkel op afspraak. Telefoneer ons hier voor."
                    } ,
                    tel : { 
                        title : "Telefoonnummers:" ,
                        text : "BE: (+32) 050950912<br />NL:(+31) 0208116096"
                    } , 
                    adres : {
                        BE : {
                            title : "Menen - België" ,
                            adres : "Bruggestraat 112<br />BE-8930 Menen"
                        } ,
                        NL : {
                            title : "Rotterdam - Nederland" ,
                            adres : "SSS Beveiliging<br />Jonker Fransstraat 88A<br /s>NL-3031AW Rotterdam"
                        }
                    }
                }  
                }
            }
        } ,
        en : {
            translation : {
                siteData : {
                    header : "Spy3k" , 
                    subheader : "Everyone deserves the truth!"
                } ,
                menu : {
                    home : "Home" , 
                    products : "Products" , 
                    contact : "Contact" ,
                    companies : "Companies" ,
                    family : "Families" ,
                    pi : "Private Investigators"
                } ,
                site : {
                    intro : "Spy3k provides discrete video and listening solutions for anyone who needs to keep an eye on things." ,
                    companies : {
                        header : "Protect your profit, protect your business by using our cost-effective surveillance solutions." ,
                        intro :[
                            "It is certainly not a pleasant thought if you suspect your employees of theft. However, the reality does not lie: We regularly read in the news about damage suffered due to internal fraud. A European study shows that a company loses several thousand to tens of thousands of euros or lose more to internal theft and that this is often done by their own staff. The current crisis is undermining the purchasing power of consumers and so theft at work is unfortunately much higher. This financial crisis also affects your turnover. And certainly during in times of reduced purchasing power you can do without theft by dishonest personnel. It is ultimately your business and you also have the right to know who is stealing and what is being stolen. So if you are unsure and you are tired of doubt: Can you exchange suspicions for clear answers by using our surveillance applications." ,
                            "As a serious business leader, you simply cannot tolerate leaking information, dereliction of duty, fraud or other malpractice. Small businesses are often at the greatest risk of going under and being forced to close down by dishonest people among the workforce. Now that you know the unpleasant and know hard facts, you no longer have to hesitate to carry out checks. It is often said where there is smoke, there is also fire and this statement is often true. You protect your own business and the employment of sincere staff ."
                        ],
                        testimony : {
                            title : "Testimony by Joris - Business manager wholesale - computer hardware" ,
                            text : "For some time now, about €7,000 a month has been thwarted from our own warehouses. This started with sporadic innocent amounts but the numbers kept rising and we suffered bigger and bigger losses. Had I not stopped these practices, I would have I can put down my company's books. We employ 50 staff and thanks to Spy3K equipment we were able to catch the culprits with hard evidence at hand. Our staff should know that this behavior is unacceptable and that I sometimes still carry out checks as a sample Overall contact with staff has improved tremendously as sincere and honest employees can no longer be unjustly suspected."
                        }} ,
                        family : {
                            header : "Do you also want to know what goes on behind your back by using our practical surveillance solutions?" ,
                            intro : [
                                "Watching your children, your car and your computer, house, nanny, ... etc 24/7 is an impossible task. And since you cannot divide yourself into 10 pieces, you can use our user-friendly surveillance devices who solve that for you. So stop questioning your intuition because you can have the evidence you are looking for to leave your family and home with peace of mind."
                            ] ,
                            testimony : {
                                title : "Testimony by Kris - Chief Financial Officer" ,
                                text : " Every Tuesday, when my wife and I are both at work, the cleaning lady comes by. However, our regular lady was on vacation and we were assigned another cleaning lady. We noticed that money and belongings had disappeared, but really we weren't sure to accuse her of this. Until my €9500 Rolex watch seemed to have disappeared. We informed the police and marked the money in the cash register. The theft of marked money was recorded with a Spy3K inconspicuous mini camera and so could the police will take the necessary steps. Our alienated items, including the watch, were found at the cleaning lady's home."
                            }
                        }  ,
                        pi : {
                            header : "Pro spy equipment for highly professional results" ,
                            intro :  [
                                "The internet, Ebay, your gadget shop, ... they all have 'spy equipment' in stock. But certainly not all devices are worth mentioning. Better, 90% of this spy equipment may be unusable. So it is difficult to to choose the right and well-functioning equipment that you can rely on so that you don't cut a figure with your customers." ,
                                "You can just order a spy device and get the disappointment, costs and loss of time for free or let us guide you in your choice. The range of Spy3K products works with two levels.<br />1: This is how we have products with an extremely strong price-quality ratio that really do what they promise and can give the ordinary user the desired answers.<br />2: And then we have the best products that deliver excellent results, which are intended for professional use."
                            ]
                        } ,
                        contact : {
                            title : "Contact" ,
                            openingsuren : {
                                title : "Opening Hours" , 
                                text : "Only by appointment. Call us for this."
                            } ,
                            tel : { 
                                title : "Phone Numbers:" ,
                                text : "BE: (+32) 050950912<br />NL:(+31) 0208116096"
                            } , 
                            adres : {
                                BE : {
                                    title : "Menen - Belgium" ,
                                    adres : "Bruggestraat 112<br />BE-8930 Menen"
                                } ,
                                NL : {
                                    title : "Rotterdam - Netherlands" ,
                                    adres : "SSS Beveiliging<br />Jonker Fransstraat 88A<br /s>NL-3031AW Rotterdam"
                                }
                            }
                        }  
                        
                }
            }
        } ,fr : {
            translation : {
                siteData : {
                    header : "Spy3k" , 
                    subheader : "Tout le monde mérite la vérité!"
                } ,
                menu : {
                    home : "Home" , 
                    products : "Produits" , 
                    contact : "Contacter" ,
                    companies : "Entreprises" ,
                    family : "Familles" ,
                    pi : "Détectives"
                } ,
                site : {
                    intro : "Spy3k fournit des solutions vidéo et d'écoute discrètes pour tous ceux qui ont besoin de garder un œil sur les choses." ,
                    companies : {
                        header : "Protégez vos profits, protégez votre entreprise en utilisant nos solutions de surveillance rentables." ,
                        intro : [
                            "Certes, il n'est pas agréable de soupçonner vos employés de vol ou de malversations. Malheureusement, la réalité ne ment pas: les  pertes dues aux fraudes internes sont légion. D'après une étude européenne, une entreprise perd chaque année plusieurs milliers voir des centaines de milliers de dollars dans des vols dont un tiers serait effectué par votre propre personnel. La crise financière compromet le bien-être des consommateurs et encourage le vol au travail. La crise affecte également vos ventes. Surtout en cette période, vous ne pouvez pas vous permettre d’encaisser des pertes supplémentaires dûes à un personnel malhonnête. Il en va de la santé de votre entreprise et vous avez le droit de savoir ce qui est volé, comment et par qui. Donc, si vous avez des soupçons, n'hésitez pas plus longtemps: nos solutions de surveillance vous apportent des solutions à vos inquiétudes.",
                            "En tant que gestionnaire, vous ne pouvez tout simplement pas vous permettre de fuite d'informations, de fraude, de manquement à une obligation ou autres pratiques sournoises. En particulier les petites entreprises sont les plus susceptibles d’embaucher des personnels malveillants. Face à la brutalité des faits, il ne faut pas hésiter à procéder à un audit. Il  n’y a pas de fumée sans feu : vous protégez votre propre entreprise et employez des salariés honnêtes."
                            
                        ] ,
                        testimony : {
                            title : "Témoignage de Joris - Responsable Vente en gros - matériel informatique" ,
                            text : "Jusqu'à récemment, il arrivait qu’il soit volé dans notre entrepôt  jusqu'à 7000 €. Cela a commencé avec des quantités inoffensives, mais les pertes ont continué à augmenter Si cela avait continué, j’aurais dû aujourd'hui fermer mon entreprise. Nous avons travaillé sur 50 membres du personnel et, aider de l'équipement Spy3K, nous avons trouvé les coupables. Mon personnel sait que de tels comportements ne sont plus tolérables et il est conscient qu’un contrôle aléatoire peut avoir lieu. Les relations de groupe au sein du personnel se sont améliorées puisque les employés honnêtes n’ont plus lieu d’être suspecté."
                        }
                    } ,
                    family : {
                        header : "Découvrez ce qui se passe derrière votre dos grâce à notre équipement de surveillance." ,
                        intro : [
                        "Vous ne pouvez pas surveiller 7 jours sur 7 et 24 heures à 24 les enfants, votre voiture et votre ordinateur, la maison, la nounou, .... Mais si vous ne pouvez pas être partout à la fois, venez découvrir nos solutions de surveillance facile à utiliser. Au lieu de douter de vos propres sentiments, vous pouvez obtenir les réponses dont vous avez besoin pour assurer la sécurité de votre famille et la protection à la maison."
                        ] ,
                        testimony : {
                            title : "Témoignage de Kris - Gestionnaire financier" ,
                            text : "Tous les mercredis, quand ma femme et moi sommes au travail, vient la femme de ménage. Notre aide régulière était en congé maladie et une nouvelle femme de ménage a été affectée. Après un temps, un peu d'argent et de petits objets semblaient disparaître, mais nous n'étions pas sûrs. Jusqu'au jour où ma montre Panerai vaut € 9,500 manquait. Nous avons contacté la police. Le vol de l'argent a été filmé avec une caméra mini-discret spy3k et la police a fait ce qui était nécessaire. Tous nos articles volés, y compris ma montre, ont été trouvés au domicile de la femme de ménage."
                    }
                }  ,
                pi : {
                    header : "Matériel d'espionnage professionnel qui donne des résultats professionnels" ,
                    intro : [
                        "Ebay, la boutique de gadget local, ... Tous offrent du  «matériel d'espionnage ». Mais pas tout le matériel est digne de mention. En effet, 90% de tous les équipements d'espionnage actuel n'est pas utilisable en pratique. Il s'agit d'un défi pour trouver le bon équipement pour aider votre client." ,
                        "Maintenant vous aussi pouvez accéder à un matériel d’espionnage en gagnant en qualité, en temps et en coût vis-à-vis de votre matériel. La gamme de produits Spy3K a deux niveaux:<br />1: Les produits avec un prix très élevé. Ils tiennent leur promesses et apportent des réponses aux besoins de la clientèle.<br />2: Les meilleurs produits dans leur créneau, qui offrent des résultats exceptionnels, destinés à un usage professionnel."
                    ] 
                } ,
                contact : {
                    title : "Contact" ,
                    openingsuren : {
                        title : "Heures d'ouverture" , 
                        text : "Uniquement sur rendez-vous. Appelez-nous pour cela."
                    } ,
                    tel : { 
                        title : "Numéros de téléphone :" ,
                        text : "BE: (+32) 050950912<br />NL:(+31) 0208116096"
                    } , 
                    adres : {
                        BE : {
                            title : "Menin - Belgique" ,
                            adres : "Bruggestraat 112<br />BE-8930 Menin"
                        } ,
                        NL : {
                            title : "Rotterdam - Pays-Bas" ,
                            adres : "SSS Beveiliging<br />Jonker Fransstraat 88A<br /s>NL-3031AW Rotterdam"
                        }
                    }
                }  
                }
            }
        } 
    }
    });

export default i18n;
