import './App.css';
import { useState } from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Container , Header , Grid , Button , Menu , Image , Item, Divider } from 'semantic-ui-react'
import './i18n';
import parse from 'html-react-parser';
//import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import * as Images from './assets/images.js'


const App = () => {
  const { t , i18n } = useTranslation();
  const [ activeItem , setActiveItem] = useState('home')
  //const navigate = useNavigate();

  const handleItemClick = (e, { name }) => {
    setActiveItem(name)
  }
  const pages = [
    'companies' , 'family' , 'pi'
  ]

  const menu = [
    'home' , 'companies' , 'family' , 'pi' , 'contact'
  ]
  const lngs = {
    nl: { nativeName: 'NL' } ,
    fr : { nativeName: 'FR'} ,
    en: { nativeName: 'EN' },
  };

  return (
    <div>
      <Container fluid id="headerDiv">
        <Header
          as='h1'
          fluid
          content={t(`siteData.header`)}
          className='headerTitle'
          inverted
    />
    <Header
      as='h2'
      fluid
      content={t(`siteData.subheader`)}
      className='headerSubtitle'
      inverted
    />
    
    <Menu
      fluid
      inverted
      secondary
      stackable
    >
      {menu.map((n) => {
        return <Menu.Item 
          name={n}
          onClick={handleItemClick}
          content={t(`menu.${n}`)}
          active={activeItem === n}
      />
      })}
      <Menu.Menu position="right">
      {Object.keys(lngs).map((lng) => (
            <Menu.Item key={lng} 
            active={i18n.resolvedLanguage === lng}
            style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </Menu.Item>
          ))}</Menu.Menu>
    </Menu>    
  </Container>
  <Container id='siteContent'>
    {!!activeItem && activeItem === 'home' &&
      <Grid stackable columns='equal'>
        <Grid.Row divided>
          <Grid.Column className='gridText' textAlign='center' verticalAlign='middle'>{t(`site.intro`)}
          <Divider />
            {
              pages.map((p) => {
                return <Button
                className='btnInText'
                size='large' vertical fluid textAlign="center"
                name={p}
                content={t(`menu.${p}`)}
                onClick={handleItemClick}
                />
              })
            }

      </Grid.Column>
          <Grid.Column><Image src={Images.eye} size='medium' centered /></Grid.Column>
        </Grid.Row>
      </Grid>
    }
    {!!activeItem && activeItem === 'contact' &&
      <Grid stackable columns='equal'>
        <Grid.Row divided>
          <Grid.Column className='gridText' textAlign='center' verticalAlign='middle'>
            <Header as="h3">{t(`site.contact.openingsuren.title`)}</Header>
            <p>
              {t(`site.contact.openingsuren.text`)}
            </p>
            </Grid.Column>
          <Grid.Column className='gridText' textAlign='center' verticalAlign='middle'>
            <Header as="h3">{t(`site.contact.tel.title`)}</Header>
            <p>
              {parse(t(`site.contact.tel.text`))}
            </p>  
          </Grid.Column>
        </Grid.Row>
        <Grid.Row divided>
          <Grid.Column className='gridText' textAlign='center' verticalAlign='middle'>
            <Header as="h3">{t(`site.contact.adres.BE.title`)}</Header>
            <p>
              {parse(t(`site.contact.adres.BE.adres`))}
            </p>
            <Image size='medium' centered src={Images.contactMenen} />
            </Grid.Column>
          <Grid.Column className='gridText' textAlign='center' verticalAlign='middle'>
            <Header as="h3">{t(`site.contact.adres.NL.title`)}</Header>
            <p>
              {parse(t(`site.contact.adres.NL.adres`))}
            </p>  
            <Image size='medium' centered src={Images.contactRotterdam} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    }
    {!!activeItem && pages.includes(activeItem) &&
      <Grid stackable columns='equal'>
          <Grid.Row divided>
          <Header as="h1">
            {t(`site.${activeItem}.header`)}
          </Header>
        </Grid.Row>
        {
          t(`site.${activeItem}.intro` , { returnObjects: true }).map((tx,i) => {
            if(i % 2 === 0)  {
              return <Grid.Row divided>
                <Grid.Column className='gridText' textAlign='center' verticalAlign='middle'>{parse(tx)}</Grid.Column>
                <Grid.Column><Image src={Images.eye} size='medium' centered /></Grid.Column>
            </Grid.Row> } else {
            return <Grid.Row divided>
                <Grid.Column><Image src={Images.eye} size='medium' centered /></Grid.Column>
                <Grid.Column className='gridText' textAlign='center' verticalAlign='middle'>{parse(tx)}</Grid.Column>
              </Grid.Row>
          }
          })
        }
        {
          i18n.exists(`site.${activeItem}.testimony`) &&
          <Item.Group>
            <Item>
              <Item.Image src={Images[activeItem]} size='small' />
              <Item.Content>
                <Item.Header as="h3">{t(`site.${activeItem}.testimony.title`)}</Item.Header>
                <Item.Description>{t(`site.${activeItem}.testimony.text`)}</Item.Description></Item.Content>
            </Item></Item.Group>
        }
         
        
      </Grid>
    }
    
  </Container>
  <Container id="footerDiv">
  &copy; {new Date().getFullYear()} Spy3k - info@spy3k.be - +32 050 95 09 12
  </Container>
    </div>
  );
}

export default App;
